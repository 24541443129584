import { action } from 'satcheljs';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';

/**
 * Action called when we have completed fetching the collaborators
 */
export const collaboratorsFetchedAction = action(
    'collaboratorsFetchedAction',
    (collaborators: UserEmailAddress[]) => ({
        collaborators,
    })
);
