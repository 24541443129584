import type { Place } from 'owa-graph-schema';
import { GetPlacesDocument } from 'hybridspace-graphql/lib/graphql/__generated__/getPlacesQuery.interface';
import { query } from 'owa-apollo';

/**
 * Get all the Places of type building in the user's tenant
 */
export async function getPlacesBuildingList(): Promise<Map<string, Place>> {
    const querystring = 'Type = "Building"';
    const { data, error } = await query(GetPlacesDocument, {
        variables: { placesRequest: { query: querystring } },
        fetchPolicy: 'cache-first',
    });

    const placeMap = new Map<string, Place>();
    (data?.places?.places || [])
        .filter(place => !!place?.id && !!place.name)
        .map(place => {
            if (place) {
                placeMap.set(place.id, place);
            }
        });

    if (!data) {
        const message = `Could not get places for this tenant ${error}`;
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(message);
    }

    return placeMap;
}
