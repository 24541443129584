import { fetchMRIsFromMsGraph } from 'accelerator-presence-service/lib/mris';
import { fetchPresence } from 'accelerator-presence-service/lib/presence';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { getSessionId } from 'owa-config';
import { getGuid } from 'owa-guid';

import type { UPSCollaborator } from '../types/UPSCollaborator';
import type {
    GetPresenceBodyType,
    PresenceIdentifier,
} from 'accelerator-presence-service/lib/types';

export default async function getUpsLocationForCollaboratorsInBuildingToday(
    collaboratorsEmails: string[]
): Promise<UPSCollaborator[]> {
    const endpointGUID = getSessionId();
    const correlationId = getGuid();

    const lowerCaseCollaboratorsEmails = collaboratorsEmails.map(email => email.toLowerCase());
    const userUPSInformationMap = new Map<string, UPSCollaborator>();
    const perfDatapoint = new PerformanceDatapoint('PlacesGetUpsLocationForCollaborators');
    perfDatapoint.addCustomData({
        emailCount: collaboratorsEmails.length,
        correlationId,
    });
    try {
        const collaboratorMRIList: PresenceIdentifier[] = [];
        const collaboratorsMRIs = await fetchMRIsFromMsGraph(
            lowerCaseCollaboratorsEmails,
            true /*Use cache*/
        );
        collaboratorsMRIs.forEach(presenceIdentifier => {
            const userSMTP = presenceIdentifier?.smtp;
            if (userSMTP) {
                collaboratorMRIList.push(presenceIdentifier);
            }
        });

        // create the body entries and initialize the userUPSInformationMap
        const collaboratorsBodyEntries: GetPresenceBodyType[] = [];
        collaboratorMRIList.forEach(presenceIdentifier => {
            const userMRI = presenceIdentifier?.mri;
            if (userMRI) {
                userUPSInformationMap.set(userMRI, {
                    primarySmtpAddress: presenceIdentifier?.smtp,
                    mailboxId: userMRI.split(':')[2] || '',
                    isCheckedIn: false,
                    buildingId: undefined,
                    isOutOfOffice: false,
                });
                collaboratorsBodyEntries.push({
                    mri: userMRI,
                    source: 'ups',
                    etag: undefined,
                } as GetPresenceBodyType);
            }
        });

        // call for the presence of the collaborators
        const collaboratorsPresence = await fetchPresence(
            collaboratorsBodyEntries,
            endpointGUID,
            correlationId
        );

        // assign the results to the UpsCollaboratorsInformation
        collaboratorsPresence.forEach(collaboratorPresence => {
            const presenceIdentifier = collaboratorPresence?.mri;
            const userUPS =
                presenceIdentifier !== undefined
                    ? userUPSInformationMap.get(presenceIdentifier)
                    : null;

            if (userUPS && presenceIdentifier && collaboratorPresence?.presence) {
                userUPS.isCheckedIn =
                    collaboratorPresence.presence?.workLocation?.locationSource ===
                    'ForcedLocation';
                userUPS.buildingId =
                    collaboratorPresence.presence?.workLocation?.approximateDetails?.id;
                userUPS.isOutOfOffice = collaboratorPresence.presence?.calendarData?.isOutOfOffice;
                userUPSInformationMap.set(presenceIdentifier, userUPS);
            }
        });
        perfDatapoint.end();
    } catch (error) {
        perfDatapoint.endWithError(DatapointStatus.ClientError);
        throw new Error('Error loading the UPS location for collaborators today');
    }
    return Array.from(userUPSInformationMap.values());
}
