import type { GetScheduleFlexibleWorkingHoursView } from 'owa-graph-schema';
import { isSameDay } from 'owa-datetime';
import { formatWeekDay } from 'owa-datetime-formatters';
import { getOwaDateFromExtendedDateTime } from 'owa-datetime-utils';
import getDayLocation from '../getDayLocation';

import type { LocationDayInfo } from 'hybridspace-shared-types';
import type { OwaDate } from 'owa-datetime';

/**
 * Helper function to transform FWH data to be displayed on a fused-day grid. It counts how many days are the same location and create a summary count.
 * @param officeDayMap map object with fwh pivoted by day.
 * @returns LocationCount[], which reduces officeDayMap to a count of continous equal FWH for each day. It groups adjacent days that have the same location
 */
const convertFlexibleWorkingHoursToLocationCount: (
    flexibleWorkingHoursView: GetScheduleFlexibleWorkingHoursView[] | undefined | null,
    daysToRender: OwaDate[]
) => LocationDayInfo[] = (
    flexibleWorkingHoursView: GetScheduleFlexibleWorkingHoursView[] | undefined | null,
    daysToRender: OwaDate[]
) => {
    const fwhInfo = daysToRender.map((currentDate: OwaDate) => {
        // To whoever is reading this, ensure this data is not localized as it is stored in indexDB.
        const dateString = formatWeekDay(currentDate);
        const location = getDayLocation(currentDate, flexibleWorkingHoursView || []);
        return {
            location,
            days: dateString,
            count: 1,
            buildingIds: getBuildingIds(currentDate, flexibleWorkingHoursView || []),
        };
    });

    if (fwhInfo.every(dayInfo => dayInfo.location === 'No Plan')) {
        const days = fwhInfo.map(dayInfo => dayInfo.days);
        return [
            {
                location: 'No Plan',
                days: days.join(','),
                count: days.length,
            },
        ];
    }

    return fwhInfo;
};

function getBuildingIds(
    date: OwaDate,
    flexibleWorkingHoursView: GetScheduleFlexibleWorkingHoursView[]
) {
    const buildingIds: string[] = [];
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    flexibleWorkingHoursView.forEach(segment => {
        if (
            isSameDay(date, getOwaDateFromExtendedDateTime(segment.start, true)) &&
            segment.workLocationType === 'Office' &&
            segment.workLocationDetails?.locationType == 'Building' &&
            segment?.workLocationDetails?.id
        ) {
            buildingIds.push(segment?.workLocationDetails?.id);
        }
    });
    return buildingIds;
}

export default convertFlexibleWorkingHoursToLocationCount;
