import { createObservableOperation } from 'owa-observable-operation';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import type { DateRange } from 'owa-datetime-utils';
import { getPlacesWorkLocationInfo } from '../services/getPlacesWorkLocationInfo';

const {
    start: loadWorkLocationInfo,
    onSucceeded: loadWorkLocationInfoSucceeded,
    onEnded: loadWorkLocationInfoEnded,
    startAsync: loadWorkLocationInfoAsync,
} = createObservableOperation(
    'PLACES_LOAD_WORK_LOCATION_INFO',
    (dateRange: DateRange) => ({ dateRange }),
    ({ dateRange }) => getDateRangeISOString(dateRange),
    ({ dateRange }) => getPlacesWorkLocationInfo(dateRange)
);

export {
    loadWorkLocationInfo,
    loadWorkLocationInfoEnded,
    loadWorkLocationInfoSucceeded,
    loadWorkLocationInfoAsync,
};
