import { logUsage } from 'owa-analytics';
import { debugLog } from '../logging/debugLog';
import { mockMRIs } from '../mock/mockMRIs';
import { shouldMockRequest } from '../mock/shouldMockRequest';
import { buildMriFromAADObjectId } from '../utils/buildMriFromAADObjectId';
import { getMRIsFromGraphInBulk } from './getMRIsFromGraphInBulk';

import type { GraphUsersResponse } from '../types/GraphUsersResponse';
import type { PresenceIdentifier } from '../types/PresenceIdentifier';
const userMRICache = new Map<string, PresenceIdentifier>();

export async function fetchMRIsFromMsGraph(
    smtps: string[],
    useInPlaceCache?: boolean
): Promise<PresenceIdentifier[]> {
    const resultReturned: PresenceIdentifier[] = [];
    const collaboratorMRIListToFetch: string[] = [];

    if (useInPlaceCache) {
        // get the MRIs from the cache
        smtps.forEach(email => {
            const mri = userMRICache.get(email);
            if (mri) {
                resultReturned.push(mri);
            } else {
                collaboratorMRIListToFetch.push(email);
            }
        });
    } else {
        collaboratorMRIListToFetch.push(...smtps);
    }

    // if the list to fetch is not empty, fetch the MRIs from the graph
    if (collaboratorMRIListToFetch.length > 0) {
        const collaboratorsMRIs = await fetchMRIs(collaboratorMRIListToFetch);
        collaboratorsMRIs.forEach((presenceIdentifier: GraphUsersResponse) => {
            const userSMTP = presenceIdentifier.mail;
            const userPresenceId = {
                mri: buildMriFromAADObjectId(presenceIdentifier.id),
                smtp: userSMTP.toLocaleLowerCase(),
            };
            resultReturned.push(userPresenceId);
            useInPlaceCache && userMRICache.set(userSMTP.toLocaleLowerCase(), userPresenceId);
        });
    }

    debugLog('fetchMRIsFromMsGraph result', smtps, resultReturned);
    return resultReturned;
}

async function fetchMRIs(smtps: string[]) {
    logUsage('fetchMRIs', { batched: smtps.length });
    debugLog('fetchMRIs', smtps);
    if (shouldMockRequest()) {
        return mockMRIs(smtps);
    }
    return getMRIsFromGraphInBulk(smtps);
}
