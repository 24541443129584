import { getAccessTokenforResource } from 'owa-tokenprovider';
import { buildAuthorizationHeader } from 'owa-tokenprovider-utils';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { MSGRAPH_RESOURCE } from '../urls/MSGraph';

import type { TraceErrorObject } from 'owa-trace';

export async function fetchIdsFromMsGraph(smtpQueryString: string): Promise<any> {
    const token = await getAccessTokenforResource(
        MSGRAPH_RESOURCE, // resource
        'idFetchFromMSGraph', // apiName
        undefined, // requestId
        undefined, // targetTenantId
        undefined, // wwwAuthenticateHeader
        false, // preferIdpToken
        getModuleContextMailboxInfo() // mailboxInfo
    );

    if (!token) {
        throw new Error('fetchIdsFromMsGraph: No access token for resource');
    }

    // Build string with all the smtps to search for this group
    const GET_MSGRAPH_SEARCH_URL: string = `${MSGRAPH_RESOURCE}v1.0/users?$select=id,mail&$count=true&$filter=mail+in(${smtpQueryString})`;

    const response = await fetch(GET_MSGRAPH_SEARCH_URL, {
        method: 'GET',
        headers: {
            Authorization: buildAuthorizationHeader(token),
            Host: 'graph.microsoft.com',
            ConsistencyLevel: 'eventual',
        },
    });

    if (isSuccessStatusCode(response.status)) {
        const jsonResponse = await response.json();
        return jsonResponse.value; // returning array that can be empty
    } else {
        const err: TraceErrorObject = new Error(
            'fetchIdsFromMsGraph failed. Encounter error making service call.'
        );
        err.diagnosticInfo = `HTTP status + ${response.status}`;
        throw err;
    }
}
