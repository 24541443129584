import { getDevErrorPageState } from 'places-diagnostics';

import { getCollaboratorsWithLocation } from './services/getCollaboratorsWithLocation';
import getPlace from './services/getPlace';
import { getPlacesBuildingList } from './services/getPlacesBuildingList';

import type {
    CollaboratorScheduleWithLocation,
    Collaborator,
} from 'hybridspace-common/lib/serviceTypes';
import type { DateRange } from 'owa-datetime-utils';
import type { CollaboratorGroup, Place } from 'owa-graph-schema';
export default class PlacesExploreClient {
    /**
     * Get all the places of type building
     */
    async getPlaces(): Promise<Map<string, Place>> {
        // Manually throw an error to test error handling
        let places;
        try {
            if (getDevErrorPageState() === 'Places') {
                throw new Error('could not get places');
            }
            places = await getPlacesBuildingList();
        } catch (error) {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error(error);
        }
        return places;
    }

    /**
     * Get the Place for a specific building
     */
    async getPlaceFromBuildingId(buildingId: string): Promise<Place> {
        // Manually throw an error to test error handling
        let place;
        try {
            if (getDevErrorPageState() === 'Place') {
                throw new Error('could not get places');
            }
            place = await getPlace(buildingId);
        } catch (error) {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error(error);
        }
        return place;
    }

    /**
     * Get collaborators schedule with building location
     */
    async getCollaboratorsLocations(
        dateRange: DateRange,
        groupsToInclude?: CollaboratorGroup[]
    ): Promise<{
        collaboratorScheduleWithLocation: CollaboratorScheduleWithLocation[];
        savedCollaborators: Collaborator[];
        suggestedCollaborators: Collaborator[];
    }> {
        let result;
        try {
            result = await getCollaboratorsWithLocation(dateRange, groupsToInclude);
        } catch (error) {
            if (error.message === 'Collaborators not found in getCollaboratorsWithLocation') {
                throw new Error('Collaborators not found');
            } else {
                throw new Error('Schedule with location not found');
            }
        }
        return {
            collaboratorScheduleWithLocation: result.collaboratorScheduleWithLocation,
            savedCollaborators: result.savedCollaborators,
            suggestedCollaborators: result.suggestedCollaborators,
        };
    }
}
