import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';

import type { FlexibleWorkingHoursView } from 'owa-graph-schema';
import type { UpsInformation } from './types/UpsInformation';

// TODO: workplanFWH & currentDayFWH can be consolidated
// We are keeping them separate to minimize PR impact and maintain the existing logic
// https://outlookweb.visualstudio.com/MicrosoftPlaces/_boards/board/t/FC%20-%20Work%20Plans/Stories/?workitem=271738
const {
    getWorkplanFWH,
    setWorkplanFWH,
    getCurrentDayFWH,
    setCurrentDayFWH,
    getUpsInformation,
    setUpsInformation,
} = createAccessors(
    'hybridspace-home-store',
    createStore('hybridspace-home-store', {
        workplanFWH: undefined as FlexibleWorkingHoursView[] | undefined, // FWH for the workplan week
        currentDayFWH: undefined as FlexibleWorkingHoursView[] | undefined, // FWH for the current day
        upsInformation: undefined as UpsInformation | undefined,
    })
);

export {
    getWorkplanFWH,
    setWorkplanFWH,
    getCurrentDayFWH,
    setCurrentDayFWH,
    getUpsInformation,
    setUpsInformation,
};
