import type { ActionCreator, ActionMessage } from 'satcheljs';
import { action, createStore, mutator, orchestrator } from 'satcheljs';
import { actionWithDatapoint } from 'owa-analytics-actions';
import { ObservableMap } from 'mobx';
import type { TraceErrorObject } from 'owa-trace';
import type { CustomDataMap } from 'owa-analytics-types';

/**
 * This function creates an observable async operation.
 *
 * @param name A name for the async operation, used to generate store and data point names.
 * @param createActionMessage A callback that defines the parameters signature of the async operation and produces an action message, which is passed to the invoke callback and other events in the operation.
 * @param getKey Returns the key of an operation, given the action messaged produced by createActionMessage. For any given key, only one operation will be started at a time.
 * @param invoke A callback that implements the async operation. It receives the action message produced by createActionMessage as parameter.
 * @param createCustomData A callback that defines custom data that will be logged in _SUCCEEDED and _FAILED events.
 * @returns A set of functions that allows the application to start an operation, query is status and subscribe to events.
 *
 * The expected usage of this function is to bind UX elements to
 * the state of an asynchronous operation.
 *
 * For example, some UX might show a list of items and a refresh button,
 * along with a spinner that indicates the application is loading items.
 * The application will call createObservableOperation, with the callback
 * that loads the items to show on the list. In return, it gets back the
 * start function, which it can associate with the onClick of the refresh
 * button, and the isInProgress function, which it can use to determine if
 * the refresh button should be disabled and if the spinner should show up.
 *
 * When start is called, a check is made to see if an existing operation with
 * the same key is already in progress, and if so, the call is ignored, otherwise
 * the state of the operation is tracked and the invoke callback is called. With
 * this in mind, the application is responsible for deciding if each call to
 * createActionMessage should produce a unique key or if there is an underlying
 * key that will be used to prevent multiple calls to start.
 *
 * The return value includes a set of satcheljs actions that allow the application
 * to bind additional mutators or orchestrators to the state changes.
 * These actions must NOT be explicitly invoked by the application.
 */
export default function createObservableOperation<
    K extends string,
    C extends (...args: any) => {},
    A extends ReturnType<C>,
    R
>(
    name: string,
    createActionMessage: C,
    getKey: (actionMessage: A) => K,
    invoke: (actionMessage: A) => Promise<R>,
    createCustomData?: (actionMessage: A) => CustomDataMap
): {
    /** Checks if an operation with the given key is in progress. */
    isInProgress: (key: K) => boolean;

    /** Returns the error of the last invocation of the operation with the given key. */
    getLastError: (key: K) => TraceErrorObject | undefined;

    /** Start a new operation, provided another operation with the same key is not already in progress. */
    start: C;

    /** Start a new operation, provided another operation with the same key is not already in progress, and returns a promise that resolves when the operation completes. */
    startAsync: (...args: Parameters<C>) => Promise<R>;

    /** Event raised when an operation starts executing. Attach to mutators or orchestrators. DO NOT INVOKE DIRECTLY. */
    onStarted: (actionMessage: A, promise: Promise<R>) => A;

    /** Event raised when an operation finishes executing. Attach to mutators or orchestrators. DO NOT INVOKE DIRECTLY. */
    onEnded: (actionMessage: A) => A;

    /** Event raised when an operation completes successfully. Attach to mutators or orchestrators. DO NOT INVOKE DIRECTLY. */
    onSucceeded: (
        actionMessage: A & {
            result: R;
        }
    ) => A & {
        result: R;
    };

    /** Event raised when an operation fails. Attach to mutators or orchestrators. DO NOT INVOKE DIRECTLY. */
    onFailed: (
        actionMessage: A,
        error?: TraceErrorObject
    ) => A & {
        error?: TraceErrorObject;
    };
} {
    // Store
    const { inProgress, errors } = createStore(name, {
        inProgress: new ObservableMap<K, Promise<R>>(),
        errors: new ObservableMap<K, TraceErrorObject | undefined>(),
    })();

    // Selector
    const isInProgress = (key: K) => !!inProgress.get(key);
    const getLastError = (key: K) => errors.get(key);

    // Action
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const start = actionWithDatapoint(name, createActionMessage as unknown as ActionCreator<A>);

    // Custom data utility
    const datapointConfigCreator = (
        actionMessage:
            | (A & {
                  result: R;
              })
            | A
    ) => {
        return createCustomData ? { customData: createCustomData(actionMessage) } : {};
    };

    // Events
    const similarActionMessage = (actionMessage: A) => actionMessage;
    const onStarted = action(name + '_STARTED', (actionMessage: A, promise: Promise<R>) => ({
        ...actionMessage,
        promise,
    }));
    const onEnded = action(name + '_ENDED', similarActionMessage);
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const onSucceeded = actionWithDatapoint(
        name + '_SUCCEEDED',
        (
            actionMessage: A & {
                result: R;
            }
        ) => actionMessage,
        datapointConfigCreator
    );
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const onFailed = actionWithDatapoint(
        name + '_FAILED',
        (actionMessage: A, error?: TraceErrorObject) => ({
            ...actionMessage,
            error,
        }),
        datapointConfigCreator
    );

    // Mutators
    mutator(onStarted, actionMessage => {
        const key = getKey(actionMessage);
        inProgress.set(key, actionMessage.promise);
        errors.delete(key);
    });

    mutator(onFailed, actionMessage => {
        const key = getKey(actionMessage);
        errors.set(key, actionMessage.error);
    });

    mutator(onEnded, actionMessage => {
        const key = getKey(actionMessage);
        inProgress.delete(key);
    });

    function startAsync(...args: Parameters<C>) {
        const actionMessage = start(...(args as Parameters<C>));
        const key = getKey(actionMessage);

        return inProgress.get(key) || Promise.reject(getLastError(key));
    }

    // Orchestrators
    orchestrator(start, async actionMessage => {
        const key = getKey(actionMessage);
        const { type, dp, ...args } = actionMessage as ActionMessage;
        const actionMessageWithoutType = args as A;
        if (!isInProgress(key)) {
            try {
                const promise = invoke(actionMessage);
                onStarted(actionMessageWithoutType, promise);
                const result = await promise;
                onSucceeded({ ...actionMessageWithoutType, result });
            } catch (e) {
                onFailed({ ...actionMessageWithoutType }, e as TraceErrorObject);
            } finally {
                onEnded(args as A);
            }
        }
    });

    return {
        isInProgress,
        getLastError,
        start: start as unknown as C,
        startAsync,
        onStarted,
        onEnded,
        onSucceeded,
        onFailed,
    };
}
