import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesPeopleStore" */ './lazyIndex')
);

export const lazyPlacesPeopleStore = new LazyImport(lazyModule, m => m);

export { deletePlacesPeopleCache } from './selectors/deletePlacesPeopleCache';
export { cleanPlacesPeopleCache } from './selectors/cleanPlacesPeopleCache';
export { addPeopleInfoToCache } from './selectors/addPeopleInfoToCache';
export { usePeopleFromCache } from './selectors/usePeopleFromCache';
export { usePeopleFromCacheForDateRange } from './selectors/usePeopleFromCacheForDateRange';
export { useCollaboratorsFromCache } from './selectors/useCollaboratorsFromCache';
export { useCollaboratorSchedule } from './selectors/useCollaboratorSchedule';
export { usePeopleFromCacheForDateRangeCount } from './selectors/usePeopleFromCacheForDateRangeCount';
export { useCollaboratorLocationFromCache } from './selectors/useCollaboratorLocationFromCache';

export {
    getCollaboratorScheduleLocations,
    getCollaboratorsOfficeLocations,
    getSavedCollaborators,
    getSuggestedCollaborators,
    getServiceCallsCompleted,
} from './store';

export type { PeopleInfo, WorkGroupCache, InternalWorkGroupCache } from './database';
export { placesPeopleTable, workGroupsTable, upsPeopleTable } from './database';

export type { PlacesPeopleStoreContextType } from './PeopleStoreContext';
export {
    PlacesPeopleStoreContext,
    usePlacesPeopleStoreContextProvider,
} from './PeopleStoreContext';
