import type { DateRange } from 'owa-datetime-utils';
import type {
    WorkLocationType,
    Place,
    FlexibleWorkingHoursView,
    FlexibleWorkingHours,
} from 'owa-graph-schema';
import { getUserExternalDirectoryUserGuid } from 'accelerator-aad/lib/getUserExternalDirectoryUserGuid';
import { fetchPresence } from 'accelerator-presence-service/lib/presence';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getGuid } from 'owa-guid';
import {
    getCurrentSegmentLocation,
    getPlace,
    getPlaceFromWorkspace,
    getPlacesEmployeeFWH,
} from 'places-service';

import type {
    GetPresenceBodyType,
    PresenceResponseType,
    PresenceViewResult,
} from 'accelerator-presence-service/lib/types';
export type WorkLocationInfo = {
    relativeLocation: WorkLocationType;
    workspaceId: string | undefined;
    workspaceName: string | undefined;
    place: Place | undefined;
    fwh: FlexibleWorkingHoursView[] | FlexibleWorkingHours[];
};

export async function getPlacesWorkLocationInfo(
    currentCalendarDateRangeView: DateRange
): Promise<WorkLocationInfo> {
    const sessionId = getGuid();
    let workInfo = {
        relativeLocation: 'Unspecified',
        workspaceId: undefined,
        workspaceName: undefined,
        place: undefined,
        fwh: [],
    } as WorkLocationInfo;

    const perfDatapoint = new PerformanceDatapoint('PlacesGetWorkLocationInfo');

    try {
        const [employeeFWHResponse, collaboratorsPresenceResponse] = await Promise.allSettled([
            getPlacesEmployeeFWH(currentCalendarDateRangeView),
            isFeatureEnabled('msplaces-enable-ups-checkin') &&
                fetchPresence(
                    [
                        {
                            mri: `8:orgid:${getUserExternalDirectoryUserGuid()}`,
                            source: 'ups',
                            etag: undefined,
                        } as GetPresenceBodyType,
                    ],
                    'PlacesExplore',
                    sessionId
                ),
        ]);

        const employeeFWHIsSuccessful = employeeFWHResponse.status === 'fulfilled';
        const collaboratorsPresenceIsSuccessful =
            collaboratorsPresenceResponse.status === 'fulfilled';

        if (employeeFWHIsSuccessful && collaboratorsPresenceIsSuccessful) {
            perfDatapoint.end();
        } else {
            perfDatapoint.addCustomData({
                correlationId: sessionId,
                reason: `employeeFWHResponse: ${employeeFWHResponse.status} collaboratorsPresenceResponse: ${collaboratorsPresenceResponse.status}`,
            });
            perfDatapoint.endWithError(DatapointStatus.ServerError);
        }

        const employeeFWH = employeeFWHIsSuccessful ? employeeFWHResponse.value : [];
        const collaboratorsPresence = collaboratorsPresenceIsSuccessful
            ? (collaboratorsPresenceResponse.value as PresenceResponseType[])
            : ([] as PresenceResponseType[]);

        const currentSegment = getCurrentSegmentLocation(
            employeeFWH,
            currentCalendarDateRangeView.start
        );

        // Get the current presence of the user
        const currentPresence = collaboratorsPresence?.[0] as PresenceViewResult;

        const userUpsWorkLocation = currentPresence?.workLocation?.location;
        const userUpsLocationId = currentPresence?.workLocation?.approximateDetails?.id;
        const userUpsLocationName = currentPresence?.workLocation?.approximateDetails?.name;

        const userWorkLocation = userUpsWorkLocation ?? currentSegment.workLocation;

        // We set the default work location info to return, if work location is empty we set unspecified
        workInfo = {
            relativeLocation: userWorkLocation ?? 'Unspecified',
            workspaceId: undefined,
            workspaceName: undefined,
            place: undefined,
            fwh: employeeFWH,
        } as WorkLocationInfo;

        if (userUpsLocationId) {
            const hasWorkspace = currentSegment.locationDetails?.locationType === 'WorkSpace';

            try {
                const place = await getPlace(userUpsLocationId);
                workInfo = {
                    relativeLocation: userWorkLocation,
                    workspaceId: hasWorkspace ? currentSegment.locationDetails?.id : undefined,
                    workspaceName: hasWorkspace
                        ? currentSegment.locationDetails?.displayName
                        : undefined,
                    place,
                    fwh: employeeFWH,
                } as WorkLocationInfo;
            } catch (error) {
                // if we fail to get place info, we fall back to constructing our own place
                workInfo = {
                    relativeLocation: userWorkLocation,
                    workspaceId: hasWorkspace ? currentSegment.locationDetails?.id : undefined,
                    workspaceName: hasWorkspace
                        ? currentSegment.locationDetails?.displayName
                        : undefined,
                    place: {
                        id: userUpsLocationId,
                        name: userUpsLocationName,
                        type: 'building',
                    },
                    fwh: employeeFWH,
                } as WorkLocationInfo;
            }
        } else if (
            /**
             * For getSchedule, check that the current location is office
             *  */
            currentSegment.workLocation === 'Office' &&
            currentSegment.locationDetails &&
            currentSegment.locationDetails.locationType
        ) {
            // If current location is building, we try to get the Place through the ID,
            if (currentSegment.locationDetails.locationType == 'Building') {
                try {
                    const buildingId = currentSegment.locationDetails?.id;
                    if (buildingId) {
                        const place = await getPlace(buildingId);

                        workInfo = {
                            relativeLocation: currentSegment.workLocation,
                            workspaceId: undefined,
                            workspaceName: undefined,
                            place,
                            fwh: employeeFWH,
                        } as WorkLocationInfo;
                    } else {
                        throw new Error('No building Id');
                    }
                } catch (error) {
                    // if we fail to get place info, we fall back to constructing our own place
                    workInfo = {
                        relativeLocation: currentSegment.workLocation,
                        workspaceId: undefined,
                        workspaceName: undefined,
                        place:
                            currentSegment.locationDetails?.id &&
                            currentSegment.locationDetails?.displayName
                                ? {
                                      id: currentSegment.locationDetails?.id,
                                      name:
                                          currentSegment.locationDetails?.displayName ?? undefined,
                                      type: 'building',
                                  }
                                : undefined,
                        fwh: employeeFWH,
                    } as WorkLocationInfo;
                }
            }
            // If current location is a workspace, set workspace details, and fetch Place info
            else if (
                currentSegment.locationDetails.locationType == 'WorkSpace' &&
                currentSegment.locationDetails?.id
            ) {
                // if workspace id exist, try to get building info
                try {
                    const place = await getPlaceFromWorkspace(currentSegment.locationDetails.id);
                    workInfo = {
                        relativeLocation: currentSegment.workLocation,
                        workspaceId: currentSegment.locationDetails?.id,
                        workspaceName: currentSegment.locationDetails?.displayName ?? undefined,
                        place,
                        fwh: employeeFWH,
                    } as WorkLocationInfo;
                } catch (error) {
                    // if we fail to get Place info, we return the response only with relative work location && workspace info
                    workInfo = {
                        relativeLocation: currentSegment.workLocation,
                        workspaceId: currentSegment.locationDetails?.id,
                        workspaceName: currentSegment.locationDetails?.displayName ?? undefined,
                        place: undefined,
                        fwh: employeeFWH,
                    } as WorkLocationInfo;
                }
            }
        }
    } catch (error) {
        perfDatapoint.endWithError(DatapointStatus.ServerError, error);
    } finally {
        return workInfo;
    }
}
