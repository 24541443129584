import { createLazyComponent, LazyImport, LazyModule } from 'owa-bundling';
import { Loading, NetworkError } from 'owa-interstitial';

export { DEV_PANEL, WORK_PLAN, MY_ANALYTICS, PEOPLE } from './HomeRoutes';

export { default as useHybridspaceHomeLinks } from './useHybridspaceHomeLinks';
export { useDataForCollaboratorWorkPlan } from './dataLayerHooks/useDataForCollaboratorWorkPlan';

import type { ComponentProps } from 'react';
import type { LazyModuleType } from 'owa-bundling';
import type { HybridspaceCalendarControls as HybridspaceCalendarControlsType } from './lazyIndex';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceHomeRoute" */ './lazyIndex')
);
export const PlacesHomeRouteLayout = createLazyComponent(
    lazyModule,
    m => m.PlacesHomeRouteLayout,
    Loading,
    NetworkError
);

export const lazyWorkplanModule = new LazyImport(lazyModule, m => m);

export const WorkplanScreen = createLazyComponent(
    lazyModule,
    m => m.WorkplanScreen,
    Loading,
    NetworkError
);

export const HybridspaceCalendarControls = createLazyComponent<
    ComponentProps<typeof HybridspaceCalendarControlsType>,
    LazyModuleType<typeof lazyModule>,
    any
>(lazyModule, m => m.HybridspaceCalendarControls, Loading, NetworkError);

export const HybridspaceWeeklyWorkRoutineSection = createLazyComponent(
    lazyModule,
    m => m.HybridspaceWeeklyWorkRoutineSection,
    Loading,
    NetworkError
);
