import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, mergeClasses } from '@fluentui/react-components';
import {
    bundleIcon,
    PeopleCommunity20Filled,
    PeopleCommunity24Filled,
    PeopleEdit20Filled,
    PeopleEdit20Regular,
} from '@fluentui/react-icons';
import { usePlacesWorkGroupsContextProvider } from '../../context';
import { useWindowBreakpoints } from '../../utils/useWindowDimensions';
import strings from './EditCollaboratorsButton.locstring.json';
import {
    editButton,
    editIcon as styles_editIcon,
    iconAndTitleContainer,
    iconContainer,
    iconContainerSpecialAlignment,
    titleIcon,
    titleText,
    wrappingContainer,
} from './EditCollaboratorsButton.scss';

import type { DialogTriggerChildProps } from '@fluentui/react-components';

const EditIcon = bundleIcon(PeopleEdit20Filled, PeopleEdit20Regular);
const editIcon = <EditIcon className={styles_editIcon} />;

const EditCollaboratorsButton = React.forwardRef<
    HTMLButtonElement,
    DialogTriggerChildProps & {
        className?: string;
        buttonClassName?: string;
    }
>(({ className, buttonClassName, onClick, ...props }, ref) => {
    const navigate = useNavigate();
    const buttonClickCallback = React.useCallback(() => {
        navigate('/collaborators');
    }, [navigate]);

    const {
        isScreenMediumUp,
        isScreenLargeUp: useBigIcon,
        isScreenSmallDown,
    } = useWindowBreakpoints();
    const workPlanTitle = loc(strings.hybridspaceCollaboratorsTitle);

    const { onDrawerIconClick } = usePlacesWorkGroupsContextProvider();

    const isSideBarInDrawerContainer = onDrawerIconClick != undefined;

    return (
        <div className={wrappingContainer}>
            <div
                className={iconAndTitleContainer}
                onClick={isScreenSmallDown ? onDrawerIconClick : undefined}
            >
                <div
                    className={
                        !useBigIcon && !isSideBarInDrawerContainer
                            ? mergeClasses(iconContainer, iconContainerSpecialAlignment)
                            : iconContainer
                    }
                >
                    {useBigIcon ? (
                        <PeopleCommunity24Filled className={titleIcon} />
                    ) : (
                        <PeopleCommunity20Filled className={titleIcon} />
                    )}
                </div>
                <h1 className={titleText}>{workPlanTitle}</h1>
            </div>
            <Button
                ref={ref}
                onClick={onClick ?? buttonClickCallback}
                appearance="subtle"
                icon={editIcon}
                className={mergeClasses(editButton, buttonClassName)}
                data-telemetry-id="EditCollaboratorsButton"
                {...props}
            >
                {isScreenMediumUp && loc(strings.editCollaboratorsButtonText)}
            </Button>
        </div>
    );
});

export default observer(EditCollaboratorsButton, 'EditCollaboratorsButton');
