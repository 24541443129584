import type {
    FlexibleWorkingHours,
    FlexibleWorkingHoursView,
    ListFlexibleWorkingHoursResponse,
} from 'owa-graph-schema';
import { getUserEmailAddress } from 'accelerator-aad/lib/getUserEmailAddress';
import { GetFlexibleWorkingHoursForEmployeeDocument } from 'hybridspace-graphql/lib/graphql/__generated__/GetFlexibleWorkingHoursForEmployeeQuery.interface';
import { RecurringFlexibleWorkingHoursQueryDocument } from 'hybridspace-graphql/lib/graphql/__generated__/recurringFlexibleWorkingHoursQuery.interface';
import { logUsage } from 'owa-analytics';
import { query } from 'owa-apollo';
import { addDays, addMinutes, getDay, getISOString, isBefore, userDate } from 'owa-datetime';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

import type { DateRange } from 'owa-datetime-utils';

export type fetchFWHForWeekResponse = {
    occurringFlexibleWorkingHours: FlexibleWorkingHoursView[];
    recurringFlexibleWorkingHours: ListFlexibleWorkingHoursResponse;
};

export default async function fetchFWHForWeek(
    currentCalendarDateRangeView: DateRange
): Promise<fetchFWHForWeekResponse> {
    const queryStartDate = currentCalendarDateRangeView.start;
    const queryEndDate = addDays(
        currentCalendarDateRangeView.end,
        1 /* add one day to create the range */
    );

    const invalidRange = isBefore(queryEndDate, queryStartDate);
    if (invalidRange) {
        logUsage('Places_useEmployeeFlexibleWorkingHours_rangeError', {
            message: `Invalid dateRange for ${queryStartDate} to ${queryEndDate}`,
        });
        throw new Error('Invalid dateRange');
    }

    // getting the work location for a particular day [startDate, endDate)
    const { data: occurrenceDetails, error: occurrenceDetailsError } = await query(
        GetFlexibleWorkingHoursForEmployeeDocument,
        {
            variables: {
                input: {
                    mailboxId: getUserEmailAddress(),
                    startView: getISOString(queryStartDate), // start of the week
                    endView: getISOString(queryEndDate), // end of the week
                },
            },
        }
    );
    if (occurrenceDetailsError) {
        throw new Error("could not get the user's occurring flexible working hours");
    }

    const flexibleWorkingHours: FlexibleWorkingHoursView[] =
        occurrenceDetails?.flexibleWorkingHoursView?.segments ?? [];

    const modifiedOccurringFlexibleWorkingHours = [...flexibleWorkingHours];
    const { data: recurringDetails, error: recurringDetailsError } = await query(
        RecurringFlexibleWorkingHoursQueryDocument,
        {
            variables: {
                input: {
                    mailboxId: getModuleContextMailboxInfo().mailboxSmtpAddress,
                },
            },
        }
    );
    if (recurringDetailsError || !recurringDetails.flexibleWorkingHours) {
        throw new Error("could not get the user's recurring flexible working hours");
    }
    if (recurringDetails.flexibleWorkingHours?.segments?.length === 0) {
        return {
            occurringFlexibleWorkingHours: [] as FlexibleWorkingHoursView[],
            recurringFlexibleWorkingHours: recurringDetails.flexibleWorkingHours,
        };
    }

    if (recurringDetails && flexibleWorkingHours.length > 0) {
        // we need to make sure that all recurring days appear in occurring days
        recurringDetails?.flexibleWorkingHours?.segments?.forEach(
            (recurringSegment: FlexibleWorkingHours) => {
                const recurringDay = getDay(userDate(recurringSegment.start.dateTime));
                // if we do not find a matching day for recurring and occurring
                if (
                    !modifiedOccurringFlexibleWorkingHours.some(
                        occurringSegment =>
                            getDay(userDate(occurringSegment.start.dateTime)) == recurringDay
                    )
                ) {
                    // the recurring date may not be in the current week context so we need to grab the day in the context of the current week
                    const beginningOfWeek = queryStartDate;
                    const recurringDateToAddToOccurringData = addDays(
                        beginningOfWeek,
                        recurringDay
                    );

                    // artificially inserting a day to ensure that recurring days are shown in occurring work plan
                    modifiedOccurringFlexibleWorkingHours.push({
                        id: 'INSERT_RECURRING_DAY',
                        workLocationType: 'Unspecified',
                        start: {
                            dateTime: getISOString(recurringDateToAddToOccurringData),
                            timeZone: {
                                name: recurringDateToAddToOccurringData.tz,
                            },
                        },
                        end: {
                            dateTime: getISOString(
                                addMinutes(recurringDateToAddToOccurringData, 30)
                            ), // end time cannot be the same as the start time
                            timeZone: {
                                name: recurringDateToAddToOccurringData.tz,
                            },
                        },
                        occurrenceDetails: {
                            seriesId: '',
                            seriesMasterId: '',
                        },
                        isConfirmed: false,
                        workLocationDetails: null,
                    });
                }
            }
        );
    }
    return {
        occurringFlexibleWorkingHours: modifiedOccurringFlexibleWorkingHours,
        recurringFlexibleWorkingHours: recurringDetails.flexibleWorkingHours,
    };
}
