import { getBrowserHeight, getBrowserWidth } from 'owa-config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    FLUENT_LARGE_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_MEDIUM_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_SMALL_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_XLARGE_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_XXLARGE_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_XXXLARGE_SCREEN_WIDTH_IN_PIXELS,
} from '../constants/responsivenessBreakpoints';

function getWindowDimensions() {
    const width = getBrowserWidth(true /** skipCache */);
    const height = getBrowserHeight(true /** skipCache */);
    return {
        width,
        height,
    };
}

/**
 * Use this hook to get window innerWidth and innerHeight.
 * We are using ResizeObserver where possible and only using resize event as a fallback.
 * If checking for specific breakpoints use useIsWindowBreakpoint.
 */
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions);

    const handleResize = useCallback(() => {
        setWindowDimensions(getWindowDimensions());
    }, [setWindowDimensions]);

    useEffect(() => {
        let resizeObserver: ResizeObserver | undefined;

        if ('ResizeObserver' in window) {
            resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(document.body);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            resizeObserver?.unobserve(document.body);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowDimensions;
}

/**
 * Check if the window is at a specific breakpoint.
 * Breakpoints follow the same pattern as Fluent UI. isScreenMediumUp is the same as Fluent UI's ms-screen-md-up, etc.
 * isScreenSmallUp doesn't have a Fluent UI counterpart as it is the smallest breakpoint so everything shuold be Small Up.
 * isScreenSmallUp >= 320
 * isScreenSmallDown < 480
 * isScreenMediumUp >= 480
 * isScreenMediumDown < 640
 * isScreenLargeUp >= 640
 * isScreenLargeDown < 1024
 * isScreenXLargeUp >= 1024
 * isScreenXLargeDown < 1366
 * isScreenXXLargeUp >= 1366
 * isScreenXXLargeDown < 1920
 * isScreenXXXLargeUp >= 1920
 * @returns Object with boolean values for each breakpoint.
 */
export const useWindowBreakpoints = (): {
    isScreenSmallUp: boolean;
    isScreenSmallDown: boolean;
    isScreenMediumUp: boolean;
    isScreenMediumDown: boolean;
    isScreenLargeUp: boolean;
    isScreenLargeDown: boolean;
    isScreenXLargeUp: boolean;
    isScreenXLargeDown: boolean;
    isScreenXXLargeUp: boolean;
    isScreenXXLargeDown: boolean;
    isScreenXXXLargeUp: boolean;
} => {
    const { width } = useWindowDimensions();

    return useMemo(() => {
        return {
            isScreenSmallUp: width >= FLUENT_SMALL_SCREEN_WIDTH_IN_PIXELS,
            isScreenSmallDown: width < FLUENT_MEDIUM_SCREEN_WIDTH_IN_PIXELS,
            isScreenMediumUp: width >= FLUENT_MEDIUM_SCREEN_WIDTH_IN_PIXELS,
            isScreenMediumDown: width < FLUENT_LARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenLargeUp: width >= FLUENT_LARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenLargeDown: width < FLUENT_XLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXLargeUp: width >= FLUENT_XLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXLargeDown: width < FLUENT_XXLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXXLargeUp: width >= FLUENT_XXLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXXLargeDown: width < FLUENT_XXXLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXXXLargeUp: width >= FLUENT_XXXLARGE_SCREEN_WIDTH_IN_PIXELS,
        };
    }, [width]);
};
