import { debugLog } from '../logging/debugLog';
import { logUsage } from 'owa-analytics';
import { fetchIdsFromMsGraph } from './fetchIdsFromMsGraph';
import type { GraphUsersResponse } from '../types/GraphUsersResponse';

export async function getMRIsFromGraphInBulk(smtps: string[]): Promise<GraphUsersResponse[]> {
    const mrisToReturn = [];
    if (smtps) {
        // Break up the emails into groups of 200 to avoid hitting the max url length
        const groupSize = 200;
        const groups = [];
        if (smtps.length > groupSize) {
            for (let i = 0; i < smtps.length; i += groupSize) {
                groups.push(smtps.slice(i, i + groupSize));
            }
        } else {
            groups.push(smtps);
        }

        for (const group of groups) {
            // Build string with all the smtps to search for this group
            const smtpsToQueryString = buildStringWithSmtpsToQuery(group);
            // If the smtpsToQueryString is over 10000 chars, we need to break it up into two groups
            if (smtpsToQueryString.length > 10000) {
                const half = Math.floor(group.length / 2);
                const firstHalf = group.slice(0, half);
                const secondHalf = group.slice(half);
                groups.push(firstHalf, secondHalf);
            } else {
                let mrisResponse;
                try {
                    mrisResponse = await fetchIdsFromMsGraph(smtpsToQueryString);
                } catch (error) {
                    logUsage('fetchIdsFromMsGraph_fail', { error: error.toString() });
                    debugLog('fetchIdsFromMsGraph_fail', error.toString());
                    mrisResponse = group.map(smtp => {
                        return { id: '', mail: smtp };
                    });
                }
                if (mrisResponse) {
                    mrisToReturn.push(...mrisResponse);
                }
            }
        }
    }
    return mrisToReturn;
}

function buildStringWithSmtpsToQuery(smtps: string[]): string {
    const smtpsToQuery = smtps.map(smtp => `"${encodeURIComponent(smtp)}"`).join(',');
    return smtpsToQuery;
}
