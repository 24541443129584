import { createLazyComponent, LazyModule, LazyImport } from 'owa-bundling';
import { Loading, NetworkError } from 'owa-interstitial';

import type { ComponentProps } from 'react';
import type { LazyModuleType } from 'owa-bundling';

import type { CollaboratorsModal } from './lazyIndex';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceHomeCollaborators" */ './lazyIndex')
);

export const lazyCollaboratorsModule = new LazyImport(lazyModule, m => m);

export const HybridspaceCollaborators = createLazyComponent(
    lazyModule,
    m => m.HybridspaceCollaborators,
    Loading,
    NetworkError
);

export const HybridspaceCollaboratorsModal = createLazyComponent<
    ComponentProps<typeof CollaboratorsModal>,
    LazyModuleType<typeof lazyModule>,
    any
>(lazyModule, m => m.CollaboratorsModal, Loading, NetworkError);

export const EditCollaboratorsModal = createLazyComponent(
    lazyModule,
    m => m.EditCollaboratorsModal,
    Loading,
    NetworkError
);
