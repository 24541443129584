import { createStore, mutatorAction } from 'satcheljs';
import { createAccessors } from 'owa-viewstate-store';
import { ObservableMap } from 'mobx';
import type { DateRange } from 'owa-datetime-utils';
import { getISOString } from 'owa-datetime';
import type { Collaborator } from 'hybridspace-common/lib/serviceTypes';
const getStore = createStore('places-people-schedule-store', {
    collaboratorScheduleLocations: new ObservableMap<string, Map<number, string>>(),
    collaboratorsOfficeLocations: new ObservableMap<number, Map<string, string[]>>(),
    savedCollaborators: [] as Collaborator[],
    suggestedCollaborators: [] as Collaborator[],
    serviceCallsCompleted: [] as string[],
});

export const setCollaboratorLocationsServiceCallComplete = mutatorAction(
    'setCollaboratorLocationsServiceCallComplete',
    (dateRange: DateRange) => {
        const key = getISOString(dateRange.start) + '--' + getISOString(dateRange.end);
        if (getStore().serviceCallsCompleted.indexOf(key) === -1) {
            getStore().serviceCallsCompleted.push(key);
        }
    }
);

export const {
    getCollaboratorsOfficeLocations,
    setCollaboratorsOfficeLocations,
    getCollaboratorScheduleLocations,
    setCollaboratorScheduleLocations,
    getSavedCollaborators,
    setSavedCollaborators,
    getSuggestedCollaborators,
    setSuggestedCollaborators,
    getServiceCallsCompleted,
} = createAccessors('places-people-schedule-store', getStore);
